@import '../styles/const';

.events {
  margin-bottom: calcVH(66);

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: calcVH(32);
    line-height: calcVH(56);
    margin-bottom: calcVH(24);
  }

  &__item {
    margin-bottom: calcVH(16);
    position: relative;

    &-line {
      position: absolute;
      height: 100%;
      left: 0;
      width: calcVH(2);
    }
  }

  &__time {
    font-weight: 600;
    font-size: calcVH(24);
    line-height: calcVH(36);
    color: $color-dark;
    padding: 0 calcVH(16);
  }

  &__name {
    font-weight: 400;
    font-size: calcVH(16);
    line-height: calcVH(24);
    color: $color-dark;
    padding: 0 calcVH(16);
  }
}
