@import '../styles/const';

.today {
  text-transform: uppercase;

  &--light {
    color: $color-light;
    font-weight: 800;
    font-size: calcVH(40);
    line-height: calcVH(60);
  }

  &--dark {
    font-weight: 800;
    font-size: calcVH(32);
    line-height: calcVH(56);
    margin-bottom: calcVH(24);
  }
}
