@import '../styles/const';

.wifi {
  font-weight: 600;
  font-size: calcVH(28);
  color: $color-light;
  line-height: calcVH(42);

  &__list {
    display: flex;
    align-items: center;
  }

  &__title {
    text-transform: uppercase;
    position: relative;
    min-width: calcVH(155);
    margin-right: calcVH(16);

    &:after {
      content: ' ';
      position: absolute;
      right: calcVH(-8);
      bottom: calcVH(11);
      width: calcVH(1);
      height: calcVH(16);
      background-color: $color-light;
    }
  }

  &__password {
    word-break: break-all;
  }

  &__qr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calcVH(10);
    width: calcVH(80);
    height: calcVH(80);
    background: $color-light;
    border-radius: 20px;
    overflow: hidden;

    img {
      max-width: calcVH(80);
      height: calcVH(80);
    }

    &--right {
      order: 2;
      margin-left: calcVH(32);
    }

    &--left {
      order: 1;
      margin-right: calcVH(32);
    }

  }


}
