@import '../styles/const';

.fullscreen {
  &__button {
    display: flex;
    align-items: center;
    color: $color-note;
  }

  &__text {
    font-weight: 600;
    font-size: calcVH(16);
    line-height: calcVH(24);
    margin-right: calcVH(24);
  }

  &__img {
    width: calcVH(23);
    height: calcVH(23);
  }
}
