@import '../styles/const';

.logo {
  margin-bottom: calcVH(60);

  &__img {
    max-height: calcVH(80);
    max-width: calcVH(500);
  }
}
