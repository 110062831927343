@import '../styles/const';

.container {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: calcVH(40);

  &:first-child {
    background-color: $color-light;
    width: 40%;
  }

  &:last-child {
    width: 60%;
  }
}
