@import '../styles/const';

.section {
  margin-bottom: calcVH(100);
}

.reception {
  &__title {
    font-weight: 900;
    font-size: calcVH(56);
    line-height: 130%;
    color: $color-dark;
  }
  &__company-name {
    word-break: break-all;
  }
}

.apps {
  margin-bottom: calcVH(50);

  &__qr {
    display: flex;
    align-items: center;
    margin: calcVH(40) 0 calcVH(50) 0;
  }

  &__qr--img {
    width: calcVH(97);
    height: calcVH(96);
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__link {
    &:first-child {
      margin-right: calcVH(24);
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: calcVH(28);
    line-height: calcVH(42);
    color: $color-dark;
    margin-left: calcVH(24);
  }

  &__google {
    width: calcVH(176);
    height: calcVH(52);
  }

  &__apple {
    width: calcVH(161);
    height: calcVH(52);
  }
}

