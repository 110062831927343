// color

$color-light: #fff;
$color-dark: #191919;
$color-note: #A1AEB7;
$color-primary: #3C7BFF;

// size elem

$REFERENCE_HEIGHT: 720;

@function calcVH($px) {
  @return calc(($px / $REFERENCE_HEIGHT * 100) * 1vh);
}
